import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styled from 'styled-components';
import Logo from './Logo';
import { BurgerButton, CloseBurgerButton } from './Button';
import settings from '../settings.json';

const Wrapper = styled.div`
    display: flex;
    width: 164px;
    min-width: 164px;
    min-height: 100vh;
    background-color: #292929;
    z-index: 5;
    position: fixed;

    @media only screen and (max-width: 420px) {
        width: 100%;
        min-height: 50px;
        z-index: 5;
    }
`;

const Container = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: 36px;
    margin-bottom: 36px;
    width: 100%;

    @media only screen and (max-width: 420px) {
        flex-flow: row;
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
        padding: 0 20px;
    }
`;

const NavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;

    margin-top: 18px;

     @media only screen and (max-width: 420px) {
        display: none;
    }
`;

const MobileNavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    margin-top: 18px;
`;

const NavItem = styled.li`
    display: flex;
    -webkit-align-items: center;
    margin: 0;
    background-color: ${({ background }) => background};
`;

const NavLink = styled.a`
    position: relative;
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 22px;
    font-family: 'Stapel';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 150% */
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    max-width: ${({ maxWidth }) => maxWidth || '93px'};

    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        padding-bottom: 4px;
    } 
    
    &:hover {
        color: #fff;
        text-decoration: none;
    }
    
    &.newLabel:after {
        content: 'NEW';
        position: absolute;
        top: -2px;
        right: 0;
        background: #A4C3EC;
        border-radius: 4px;
        color:#292929;
        min-width: 24px;
        height: 10px;
        min-height: 10px;
        max-height: 10px;
        display: inline-flex;
        font-size: 7px;
        align-items: center;
        justify-content: center;
        margin-left: 2px;
        position: relative;
        -webkit-justify-content: center;
        -webkit-align-items: center;
        -webkit-align-self: center;
    }
`;

const SupportLink = styled.a`
    font-family: Stapel;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 22px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    max-width: 93px;
    margin-top: auto;

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    @media only screen and (max-width: 420px) {
        margin-left: auto;
        margin-top: 0;
        color: #C1FF73;
        height: auto;

        &:hover {
            color: #C1FF73;
        }
    }
`;

const MobileContainer = styled.div`
    position: absolute;
    top: 32px;
    left: 0;
    display: flex;
    width: 164px;
    height: 100vh;
    background-color: #292929;
    z-index: 5;
    transition: .3s;
`;

const LangContainer = styled.div`
    display: flex;
    flex-flow: row;
    margin-left: 11px;
    margin-top: 11px;

    @media only screen and (max-width: 420px) {
         margin-top: 0;
         align-items: center;
    }
`;

const LangSwitcher = styled.button`
    width: 18px;
    height: 18px;
    color: #FFF;
    font-family: Stapel;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
    border: none;
    background: transparent;

    margin-left: 10px;
    padding: 0;

    &:focus {
        outline: none;
    }
`;

const NavigationContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
`;

const NavigationContent = ({ activeTab, t, isMobile, language, isXrayAllowed, isOpen }) => (<NavigationContentWrapper>
    <NavItem background={activeTab === '/' ? 'rgba(255, 255, 255, 0.20)' : 'transparent'} >
        <NavLink href="/">{t('MySubs')}</NavLink>
    </NavItem>
    <NavItem background={activeTab === 'profile' ? 'rgba(255, 255, 255, 0.20)' : 'transparent'}>
        <NavLink href="/profile">{t('MyProfile')}</NavLink>
    </NavItem>
    <NavItem background={activeTab === 'referal' ? 'rgba(255, 255, 255, 0.20)' : 'transparent'}>
        <NavLink className="newLabel" maxWidth="auto" href="/referal">{t('FreeDays')}</NavLink>
    </NavItem>
    <NavItem key={`${language}-instruction`}  background={activeTab === 'test' ? 'rgba(255, 255, 255, 0.20)' : 'transparent'}>
        <NavLink maxWidth="90%" href={language === 'en' ? settings.betaInstructionEn : settings.betaInstructionRu}>{t('TestMenu')}</NavLink>
    </NavItem>
    {isMobile && !isOpen && <SupportLink href="mailto:support@papervpn.io"> {t('WriteSupportMobile')}</SupportLink>}
</NavigationContentWrapper>)

export const NavMenu = ({ isXrayAllowed }) => {
    const [activeTab, setActiveTab] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const navRef = useRef(null);
    const { t, i18n } = useTranslation();

    const handleOpen = (e) => {
        e && e.preventDefault();
        setIsOpen(!isOpen);
    }

    const handleScroll = () => {
        if (isOpen) {
            handleOpen();
        }
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    const handleLangChange = (e, lang) => {
        e && e.preventDefault();
        i18n.changeLanguage(lang);
        axios.post(`api/setlang?lang=${lang === "en" ? 1 : 0}`).then(
            (res) => {
                if (res.status === 200 && res.data.error === null) {
                    i18n.changeLanguage(lang);
                } else {
                    console.error(res.data.error)
                }
            }
        );
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                handleOpen();
            }
        }

        window.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', handleScroll);
        if (window.location.pathname.includes('profile')) {
            setActiveTab('profile')
        } else if (window.location.pathname.includes('test')) {
            setActiveTab('test')
        } else if (window.location.pathname.includes('referal')) {
            setActiveTab('referal')
        } else {
            setActiveTab('/')
        }
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousedown', handleClickOutside);
        }
    }, [isOpen])

    const isMobile = width <= 420;

    return (
      <Wrapper>
            <Container>
                {(isOpen && isMobile) &&
                    <CloseBurgerButton
                        onClick={(e) => handleOpen(e)} />}
                {(!isOpen && isMobile) &&
                    <BurgerButton
                        onClick={(e) => handleOpen(e)} />}
                <Logo href={'/'} isOpen={isOpen}></Logo>
                <NavList>
                    <NavigationContent language={i18n.language} isMobile={isMobile} activeTab={activeTab} t={t} isXrayAllowed={isXrayAllowed} isOpen={isOpen} />
                </NavList>
                <SupportLink href="mailto:support@papervpn.io"> {isMobile ? t('WriteSupportMobile') : t('WriteSupport')}</SupportLink>
                <LangContainer>
                    <LangSwitcher onClick={(e) => handleLangChange(e, 'ru')}>{t("RU")}</LangSwitcher>
                    <LangSwitcher onClick={(e) => handleLangChange(e, 'en')}>{t("EN")}</LangSwitcher>
                </LangContainer>
        </Container>
        {isOpen &&
            <MobileContainer ref={navRef}>
                <MobileNavList>
                    <NavigationContent isOpen={isOpen} isMobile={isMobile} activeTab={activeTab} t={t} language={i18n.language} isXrayAllowed={isXrayAllowed} />
                </MobileNavList>
            </MobileContainer>
        }
      </Wrapper>
    );
}
