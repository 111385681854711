import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styled from 'styled-components';
import settings from '../settings.json';
import { format } from 'date-fns';
import { encode } from 'base-64';
import FieldLabel from './FieldLabel';
import Link from './Link';
import { Text, MediumHintText } from './Text';
import { PopUpWrapper, PopUpWindow, SmallPopupWrapper } from './PopUp';
import {BetaKeyButton, CloseButton, CopyButton} from './Button';
import { SubTitle } from './Title';
import { TraficLoader } from './Loader';
import { CurrentPlan } from './PlanCard';
import Icon from "./Icon";
import cancel from '../assets/icons/cancel.svg';
import extraKey from '../assets/icons/extrakey.svg';
import mainKey from '../assets/icons/mainkey.svg';
import warning from "../assets/icons/warning.svg";
import manage from "../assets/icons/manage.svg";
import managedown from "../assets/icons/managedown.svg";
import planChange from '../assets/icons/planchange.svg'
import up from '../assets/icons/icon-up.svg';
import LoaderSmall from "./LoaderSmall";
import convertUTCDateToLocalDate from "../helpers/getLocalTimeFromUTC";

const SubscriptionWrapper = styled.div`
    max-width: 530px;
    background: #F6F6F8;
    border-radius: 3px;
    margin-bottom: 18px;
    padding-left: 28px;
    padding-top: 18px;
    padding-right: 5px;
    position: relative;

     @media only screen and (max-width: 420px) {
        max-width: 335px;
        padding-right: 18px;
     }
    .popup {
        ${({isFirstChild}) => isFirstChild ?
                `
                text-transform: none !important;
                z-index: 4;
                display: block;
                content: ' ';
                right: -85px;
                top: -35px;
                position: absolute;
                width: 336px;
                height: auto; 
                border: 1px solid #FFE178;
                border-radius: 3px;
                background: #FFFEFBF2;
                font-family: Manrope;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                padding: 9px 15px 15px 30px;
                
                @media only screen and (max-width: 420px) {
                    max-width: 362px;
                    height: 123px;
                    left: 0px;
                    top: -122px;
                    padding: 9px 15px 30px 15px;
                }

                ` : 'display: none;'
        }
    }

    .popup-arrow {
        ${({isFirstChild}) => isFirstChild ?
                `
                z-index: 4;
                display: block;
                content: ' ';
                position: absolute;
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-right: 15px solid #FFE178;
                top: 11px;
                right: 252px;
                     &:after {
                        display: block;
                        content: ' ';
                        position: absolute;
                        border-top: 9px solid transparent;
                        border-bottom: 9px solid transparent;
                        border-right: 17px solid #FFFEFBF2;
                        top: -9px;
                        left: 0px;
                     }
                @media only screen and (max-width: 420px) {
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-top: 15px solid #FFE178;
                    top: 0px;
                    left: 21px;
                    &:after {
                        display: block;
                        content: ' ';
                        position: absolute;
                        border-left: 9px solid transparent;
                        border-right: 9px solid transparent;
                        border-top: 17px solid #FFFEFBF2;
                        top: -20px;
                        left: -9px;
                     }
                }
                ` : ''
        }
    }
`;

const InfoWrapper = styled.div`
    margin-bottom: 33px;
    margin-top: 20px;
`;

const ControllersWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: auto;
    
    &:last-child {
        border-bottom: 0;
    }
`;

const Container = styled.div`
    position: relative;
    padding: ${({ padding }) => padding || '13px 35px'};
    height: 100%;
    display: flex;
    flex-flow: column;

    @media only screen and (max-width: 420px) {
        padding: ${({ mobilePadding }) => mobilePadding || '13px 35px'};
    }
`;

const KeyWrapper = styled.div`
    position: relative;
    width: ${({ width }) => width || '640px'};
    height: auto;
    padding: 6px 19px;
    color: #292929;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    background: #EEEFF4;
    cursor: pointer;
    line-break: ${({ lineBreak }) => lineBreak || 'anywhere'};

    @media only screen and (max-width: 420px) {
        width: 260px;
    }
`;

const ControlsButton = styled.a`
    width: 95%;
    position: relative;
    display: flex;
    font-family: Stapel;
    font-size: 12px;
    padding: 14px 0;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
    color: #262823;
    border-top: 1px solid #2628231A;
    
    &:hover {
        text-decoration: none;
        color: #78E801;
        transition: color 0.2s;
        cursor: pointer;
    }
    
    .down-icon {
        display: block;
        margin-left: auto;
        margin-right: 25px;
    }
`;

const AddDaysButton = styled.button`
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A4C3EC;
    font-family: Stapel;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 246px;
    height: 34px;
    color: #262823;
    border-radius: 4px;
    border: none;
`;

const CopyReaction = styled.div`
    position: absolute;
    text-transform: capitalize;
    top: 0;
    right: 0;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: #78E801;
    display: none;
`;

const Controls = styled.div`
    width: 100%;
    height: 0;
    visibility: hidden;
`;

const DetailsLink = styled.a`
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #262823;
    margin-top: ${({marginTop}) => marginTop || '0px'};
    text-transform: capitalize;
    text-decoration: underline;
    
    &:hover {
        color: #262823;
    }
`;

const Warning = styled.div`
    position: absolute;
    width: 704px;
    top: 0;
    left: 0;
    background: ${({ bg }) => bg || '#FFF6F6'};
    padding: ${({ hasIcon }) => hasIcon ? '21px 21px 17px 53px' : '21px 21px 17px 17px'};
    &:before {
        content: ' ';
        position: absolute;
        top: 21px;
        left: 18px;
        background: url("${warning}");
        background-repeat: no-repeat;
        width: 18px;
        height: 17px;
        display: ${({ hasIcon }) => hasIcon ? 'block' : 'none'};
    }

    @media only screen and (max-width: 420px) {
        max-width: 334px;
    }
`;

const WarningText = styled.div`
    max-width: 512px;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    @media only screen and (max-width: 420px) {   
        max-width: 230px;
    }
`;

export const Subscription = ({ index, id, region, paymentSum, paymentCurrency, paymentDate, length, token, email, endDate, monthNumPeriod, dataLimit, isHanging, language, isXrayAllowed, referralPromocode, gate, isPending, nextTrafficReset }) => {
    const { t } = useTranslation();
    const [remainigData, setRemainigData] = useState(0);
    const [remainigDataError, setRemainigDataError] = useState(false);
    const [isLoadingRemainigData, setIsLoadingRemainigData] = useState(true);
    const [isLoadingKey, setIsLoadingKey] = useState(false);
    const [isLoadingBetaKey, setIsLoadingBetaKey] = useState(false);
    const [key, setKey] = useState('');
    const [showKey, setShowKey] = useState(false);
    const [showBetaKey, setShowBetaKey] = useState(false);
    const [betaKey, setBetaKey] = useState('');
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showCancellationFailedPopup, setShowCancellationFailedPopup] = useState(false);
    const [showCancelForm, setShowCancelForm] = useState(false);
    const [referalPopupShow, setReferalPopupShow] = useState(false);
    const [hasEnd, setHasEnd] = useState(Boolean(endDate));
    const [keyWasCopied, setKeyWasCopied] = useState(false);
    const [availableSubs, setAvailableSubs] = useState([]);
    const [showControls, setShowControls]  = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const wrapperKeyRef = useRef(null);
    const wrapperBetaKeyRef = useRef(null);
    const wrapperCancelRef = useRef(null);
    const wrapperCancelFormRef = useRef(null);
    const wrapperCancellationFailedRef = useRef(null);
    const wrapperReferalFormRef = useRef(null);
    const isLastChild = index + 1 === length;
    const isFirstChild = index === 0;
    const authInfo = JSON.stringify({
        email: email,
        vpnId: id,
    });
    const encodedEmailAndId = encode(authInfo);

    const date = endDate ? endDate : paymentDate;

    const fetchBetaKey = () => {
        setIsLoadingBetaKey(true);
        axios.get(
            `/Api/GetXraySubKey?id=${id}`
        ).then(
            (res) => {
                setBetaKey(res.data?.data?.token)
                setIsLoadingBetaKey(false);
            },
            (err) => {
                console.error(err);
                setBetaKey('');
                setIsLoadingBetaKey(false);
            })
    }

    const handleBetaKeyPopup = (e) => {
        e.preventDefault();
        fetchBetaKey();
        setShowBetaKey(!showBetaKey);
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    const fetchRemainingData = () => {
        axios.get(
            `/api/subtraffic?id=${id}`
        ).then(
            (res) => {
                setIsLoadingRemainigData(false);
                setRemainigData(res.data.data.amount);
            },
            (err) => {
                console.error(err);
                setIsLoadingRemainigData(false);
                setRemainigDataError(true);
                setRemainigData('error');
            })
    }

    const fetchKey = () => {
        setIsLoadingKey(true);
        axios.get(
            `/api/getsubkey?id=${id}`
        ).then(
            (res) => {
                setKey(res.data?.data?.token)
                setIsLoadingKey(false);
            },
            (err) => {
                console.error(err);
                setKey('');
                setIsLoadingKey(false);
            })
    }

    const cancelSubscription = () => {
        axios.post(
            `/api/cancelsub?id=${id}`
        ).then((res) => {

            if (res.data.error !== null) {
                handleCancelPopup();
                handleCancellationFailedPopup();
            } else {
                handleCancelPopup();

                document.body.style.overflow = 'hidden';

                handleCancelForm();
                setShowControls(false);
                setHasEnd(true);

                window.dataLayer.push(
                {
                    'event': 'cancel_subscription',
                    'id': id,
                    'region': region,
                    'paymentSum': paymentSum,
                    'paymentCurrency': paymentCurrency,
                    'dataLimit': dataLimit,
                    'monthNumPeriod': monthNumPeriod,
                })
            }
        })
        document.body.style.overflow = 'unset';
    }

    const handleKeyPopup = (e) => {
        e && e.preventDefault();
        fetchKey();
        setShowKey(!showKey);
    }

    const handleCancelPopup = (e) => {
        e && e.preventDefault();
        setShowCancelPopup(!showCancelPopup);
    }

    const handleCancellationFailedPopup = (e) => {
        e && e.preventDefault();
        setShowCancellationFailedPopup(!showCancellationFailedPopup);
    }

    const handleCancelForm = (e) => {
        e && e.preventDefault();
        if (showCancelForm) {
            document.body.style.overflow = 'unset';
        }
        setShowCancelForm(!showCancelForm);
    }

    const handleKeyCopy = (e) => {
        e && e.preventDefault();
        navigator.clipboard.writeText(e.target.innerText);
        setKeyWasCopied(true);
        setTimeout(() => {
            setKeyWasCopied(false);
        }, 1500);
    }

    const handleReferalCopy = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(referralPromocode);
        const reaction = document.getElementsByClassName('copy-reaction')[0];
        reaction.style.display = 'block';
        setTimeout(() => {
            reaction.style.display = 'none';
        }, 1500);
    }

    const addEmbedScriptForCancelButton = () => {
        const root = document.getElementById("root");
        const script = document.createElement("script");
        script.src = `${settings.paymentsEndpointHost}embed.js?&lang=${language}&type=vpn-card-change-button&container_id=${id}&token=${token}&params=${encodedEmailAndId}`;
        script.id = "changeCardEmbed";
        root.appendChild(script);
    }

    const manageSubscription = () => {
        const controlsWrapper = document.getElementsByClassName(`controls-wrapper-${id}`)[0]
        const icon = document.getElementsByClassName(`down-icon-${id}`)[0];
        if (!showControls) {
            icon.src = `${up}`;
            controlsWrapper.style.visibility = 'visible';
            controlsWrapper.style.height = 'auto';
        } else {
            icon.src = `${managedown}`;
            controlsWrapper.style.visibility = 'hidden';
            controlsWrapper.style.height = '0';
        }

        setShowControls(!showControls);
    }

    const handleReferalPopup = (e) => {
        e && e.preventDefault();
        setReferalPopupShow(!referalPopupShow);
    }

    useEffect(() => {
        fetchRemainingData();
        if (!hasEnd) {
            addEmbedScriptForCancelButton();
        }

        const handleClickOutside = (event) => {
            if (wrapperKeyRef.current && !wrapperKeyRef.current.contains(event.target)) {
                setShowKey(false);
            }
            if (wrapperBetaKeyRef.current && !wrapperBetaKeyRef.current.contains(event.target)) {
                setShowBetaKey(false);
            }
            if (wrapperReferalFormRef.current && !wrapperReferalFormRef.current.contains(event.target)) {
                setReferalPopupShow(false);
            }
            if (wrapperCancelRef.current && !wrapperCancelRef.current.contains(event.target)) {
                setShowCancelPopup(false);
                setShowCancelForm(true);
            }
            if (wrapperCancelFormRef.current && !wrapperCancelFormRef.current.contains(event.target)) {
                setShowCancelForm(false);
                document.body.style.overflow = 'unset';
            }
            if (wrapperCancellationFailedRef.current && !wrapperCancellationFailedRef.current.contains(event.target)) {
                setShowCancellationFailedPopup(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [language]);

    const isMobile = width <= 420;

    return (
        <SubscriptionWrapper className="sub-wrapper" isLastChild={isLastChild} isFirstChild={isFirstChild}>
            <CurrentPlan
                canChange={availableSubs.length}
                id={id}
                paymentSum={paymentSum}
                monthNumPeriod={monthNumPeriod}
                dataLimit={dataLimit}
                currencySymbol={paymentCurrency}
            />
            <InfoWrapper>
                <>
                    <FieldLabel>
                        {t('RegionCode')}: {region}
                        <MediumHintText>{region !== 'russia' ? t('RussianRegion') : t('EuropeanRegion')}</MediumHintText>
                    </FieldLabel>
                    <FieldLabel>{t('TrafficRemain')}: {isLoadingRemainigData ? <TraficLoader/> :
                        <>
                            {!remainigDataError ? remainigData : t('TrafficError')}
                            {!remainigDataError ? t('Gb') : ''}
                            {hasEnd && new Date(nextTrafficReset) > new Date(endDate) ? '' : ` (${t('TrafficReset')} ${format(convertUTCDateToLocalDate(new Date(nextTrafficReset)), 'dd.MM.yyyy')})`}</>}</FieldLabel>
                    <FieldLabel>{hasEnd && t('SubEnds') || t('SubPayDate')}: {!hasEnd && `${paymentSum}\u00A0${paymentCurrency},`} {format(convertUTCDateToLocalDate(new Date(date)), 'dd.MM.yyyy')}</FieldLabel>
                    <FieldLabel><MediumHintText>{hasEnd && t('SubEndsHint')}</MediumHintText></FieldLabel>
                    <FieldLabel><MediumHintText>{gate === 'googlepay' && gate === 'apple' && t('SubAppHint')}</MediumHintText></FieldLabel>
                </>
                <AddDaysButton onClick={(e) => handleReferalPopup(e)}>{t('AddDays')}</AddDaysButton>
            </InfoWrapper>
            <ControllersWrapper>
                {isPending && <MediumHintText>{t('PlanUpdate')}</MediumHintText>}
                {!hasEnd && gate !== 'googlepay' && gate !== 'apple' && !isPending &&
                    <ControlsButton href={`/change?subId=${id}`} marginTop="13px"><Icon
                        src={planChange}/>{t("ChangePlan")}</ControlsButton> || null}
                {!isPending && <>
                    <ControlsButton key="main-button" className="mainKeyButton" onClick={(e) => handleBetaKeyPopup(e)}><Icon
                    src={mainKey}/>{t('MainKey')}
                    </ControlsButton>
                    <ControlsButton key="extra-button" onClick={(e) => handleKeyPopup(e)}><Icon
                        src={extraKey}/> {t('ExtraKey')}</ControlsButton>
                </>}

                {!hasEnd && gate !== 'googlepay' && gate !== 'apple' && !isPending &&
                    <ControlsButton onClick={(e) => manageSubscription(e)}><Icon src={manage}/>{t('ManageSub')}<Icon
                        width="12px" height="20px" className={`down-icon-${id} down-icon`}
                        src={managedown}/></ControlsButton>}
                <Controls className={`controls-wrapper-${id}`}>
                    {!hasEnd &&
                        <ControlsButton onClick={(e) => handleCancelPopup(e)}><Icon src={cancel}/>{t('SubCancel')}
                        </ControlsButton>}
                    {!hasEnd && <ControlsButton>
                        <div id={id}></div>
                    </ControlsButton>}
                </Controls>
            </ControllersWrapper>
            {showBetaKey &&
                <PopUpWrapper>
                    <PopUpWindow mobilePadding="0px" height="auto" ref={wrapperBetaKeyRef}>
                        <Container mobilePadding="115px 28px 33px 18px" padding="85px 28px 33px 18px">
                            <Warning hasIcon={true}>
                                <CloseButton onClick={(e) => handleBetaKeyPopup(e)}/>
                                <WarningText>{t('PopupWarning')}</WarningText>
                            </Warning>
                            <Text marginBottom="8px"><b>{t('WarningInstructionTitle')}</b></Text>
                            <Text marginBottom="8px">{t('WarningInstruction1')} <a href={language === 'en' ? settings.betaInstructionEn : settings.betaInstructionRu}>{t('WarningInstruction1Link')}</a></Text>
                            <Text marginBottom="8px">{t('WarningInstruction2')}</Text>
                            <Text marginBottom="25px">{t('WarningInstruction3')}</Text>
                            <KeyWrapper key={betaKey} onClick={(e) => handleKeyCopy(e)}>{isLoadingBetaKey ?
                                <LoaderSmall centered={true}/> : betaKey}</KeyWrapper>
                            <MediumHintText marginTop='10px'>{t('CopyKey')}</MediumHintText>
                            <Link marginTop="8px"
                                  href={language === 'en' ? settings.betaInstructionEn : settings.betaInstructionRu}>{t('WarningDetails')}</Link>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {showKey &&
                <PopUpWrapper>
                    <PopUpWindow mobilePadding="0px" height="auto" ref={wrapperKeyRef}>
                        <Container mobilePadding="115px 28px 33px 18px" padding="85px 28px 33px 18px">
                            <Warning hasIcon={false} bg="#FFF6D9">
                                <CloseButton onClick={(e) => handleKeyPopup(e)}/>
                                <WarningText>{t('ExtraKeyHint')}</WarningText>
                            </Warning>
                            <Text marginBottom="21px">{t('ExtraKeyInstruction')} <a href={language === 'en' ? settings.extraInstructionEn : settings.extraInstructionRu}>{t('ExtraKeyInstructionLink')}</a></Text>
                            <KeyWrapper key={key} onClick={(e) => handleKeyCopy(e)}>{isLoadingKey ?
                                <LoaderSmall centered={true}/> : key}</KeyWrapper>
                            <MediumHintText marginTop='10px'>{t('CopyKey')}</MediumHintText>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {showCancelPopup &&
                <PopUpWrapper>
                    <PopUpWindow ref={wrapperCancelRef}>
                        <Container>
                            <CloseButton onClick={(e) => handleCancelPopup(e)}/>
                            <Text>{t('CancelSubQestion')}</Text>
                            <Text>{t('CancelSubHint1')}</Text>
                            <ControllersWrapper>
                                <Link onClick={(e) => handleCancelPopup(e)}>{t('CloseUnsubDialog')}</Link>
                                <Link color="#FF737E" onClick={() => cancelSubscription()}>{t('SubCancel')}</Link>
                            </ControllersWrapper>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {showCancellationFailedPopup &&
                <PopUpWrapper>
                    <PopUpWindow height="auto" ref={wrapperCancellationFailedRef}>
                        <Container>
                            <CloseButton onClick={(e) => handleCancellationFailedPopup(e)}/>
                            <Text>{t('CancelSubWrong')}</Text>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {showCancelForm &&
                <PopUpWrapper>
                    <PopUpWindow ref={wrapperCancelFormRef} height="100%" mobileWidth="100%">
                        <Container>
                            <CloseButton onClick={(e) => handleCancelForm(e)}/>
                            <Text>{t('SubscriptionCancellationPromo')}</Text>
                            <Text>{t('SubsctiprionCancellationForm')}</Text>
                            {language === 'ru' && <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLSdSJZw4saEhgJgA2auDNQ2hbc7uZk6DOVLajahYG-O3B7g6nw/viewform?embedded=true"
                                    width={isMobile ? "320" : "640"} height="919" frameborder="0" marginheight="0"
                                    marginwidth="0">Загрузка…</iframe> ||
                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLScqTvAS1fzcLDd7YSgLRg4-BA0IoHYD60bRQWDOT-m6bQJIyA/viewform?embedded=true"
                                    width={isMobile ? "320" : "640"} height="881" frameborder="0" marginheight="0"
                                    marginwidth="0">Loading…</iframe>
                            }
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {referalPopupShow &&
                <PopUpWrapper>
                    <PopUpWindow className="referalPopup" ref={wrapperReferalFormRef} width="442px" height="357px"
                                 mobileWidth="335px">
                        <Container>
                            <CloseButton onClick={(e) => handleReferalPopup(e)}/>
                            <SubTitle>{t('ReferalPopupTitle')}</SubTitle>
                            <KeyWrapper lineBreak="unset" width="396px"
                                        onClick={(e) => handleReferalCopy(e)}>{t('ReferalPopupPromo1')}
                                <b>{referralPromocode}</b> {t('ReferalPopupPromo2')}</KeyWrapper>
                            <CopyButton onClick={(e) => handleReferalCopy(e)}>{t('Copy')}<CopyReaction
                                className="copy-reaction">{t('Copied')}</CopyReaction></CopyButton>
                            <DetailsLink marginTop="16px" href="/referal">{t('FreeDaysDetails')}</DetailsLink>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {keyWasCopied && <SmallPopupWrapper>
                <Text marginBottom="0px">{t('KeyCopied')}</Text>
            </SmallPopupWrapper>}
        </SubscriptionWrapper>
    )
}
