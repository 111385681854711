import styled from 'styled-components';

const Label = styled.h2`
    color: #262823;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    margin-bottom: 10px;
`;

export const ErrorLabel = styled.div`
    color: #FF737E;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
`;

export default Label;
