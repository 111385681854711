import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import axios from 'axios';
import settings from '../settings.json';
import Title from '../components/Title';
import FieldLabel from '../components/FieldLabel';
import Link from '../components/Link';
import { MediumHintText, Text } from '../components/Text';
import { PopUpWrapper, PopUpWindow } from '../components/PopUp';
import { CloseButton } from '../components/Button';
import FreeDaysBanner from "../components/FreeDaysBanner";

const Wrapper = styled.div`
    padding: 37px 97px;

    @media only screen and (max-width: 420px) {
        padding: 35px 20px;
        margin-top: 64px;
    }
`;

const ProfileInfo = styled.div`
    margin-top: 35px;
`;

const ControllersWrapper = styled.div`
    margin-top: 16px;
    display: flex;
`;

const PopupControllersWrapper = styled.div`
    display: flex;
    flex-flow: row;
    margin-top: auto;
`;

const Container = styled.div`
    position: relative;
    padding: 13px 22px;
    height: 100%;
    display: flex;
    flex-flow: column;
`;

export const Profile = ({ user}) => {
    const { t, i18n } = useTranslation();
    const [showClosePopup, setShowClosePopup] = useState(false);
    const wrapperCloseRef = useRef(null);

    const handleClosePopup = (e) => {
        e.preventDefault();
        setShowClosePopup(!showClosePopup);
    }

    const handleLogout = () => {
        localStorage.clear();
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperCloseRef.current && !wrapperCloseRef.current.contains(event.target)) {
                setShowClosePopup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperCloseRef])

    return (
        <Wrapper>
            <Title>{t('ProfileTitle')}</Title>
            <FreeDaysBanner />
            <ProfileInfo>
                <FieldLabel>{t('EmailAddress')}: <b>{user.email}</b></FieldLabel>
                <MediumHintText>
                    {t('ProfileHint1')} <a href={`mailto:${settings.supportLink}`}>{settings.supportLink}</a>
                </MediumHintText>
                <ControllersWrapper>
                    <Link href={i18n.language === 'en' ? settings.profileLinkEn : settings.profileLink}>{t('Edit')}</Link>
                    <Link onClick={(e) => handleClosePopup(e)} color="#FF737E">{t('Exit')}</Link>
                </ControllersWrapper>
            </ProfileInfo>
            {showClosePopup &&
                <PopUpWrapper>
                    <PopUpWindow ref={wrapperCloseRef}>
                        <Container>
                            <CloseButton onClick={(e) => handleClosePopup(e)} />
                                <Text>{t('ExitQuestion')}</Text>
                                <PopupControllersWrapper>
                                    <Link onClick={(e) => handleClosePopup(e)}>{t('CancelExit')}</Link>
                                <Link color="#FF737E" href="/auth/logout" onClick={() => handleLogout()}>{t('Exit')}</Link>
                                </PopupControllersWrapper>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
        </Wrapper>
    );
}
